
import commaDecimalToDecimal from "@/app/infrastructures/misc/common-library/CommaDecimalToDecimal";
import numberOnly from "@/app/infrastructures/misc/common-library/NumberOnly";
import { Options, Vue, prop } from "vue-class-component";

class Props {
  id = prop<string>({
    type: String,
    default: ""
  });
  error = prop<boolean>({
    type: Boolean,
    default: false
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorCaption = prop<string>({
    default: "",
    type: String
  });
  modelValue = prop<any>({
    required: true
  });
  placeholder = prop<string>({
    default: "",
    type: String
  });
  autocomplete = prop<string>({
    default: "off",
    type: String
  });
  type = prop<string>({
    default: "text",
    type: String
  });
  prefix = prop<string>({
    default: "",
    type: String
  });
  suffix = prop<string>({
    default: "",
    type: String
  });
  minLength = prop<string>({
    default: ""
  });
  maxLength = prop<string>({
    default: ""
  });
  min = prop<string>({
    default: "",
    type: String
  });
  max = prop<string>({
    default: "",
    type: String
  });
  format = prop<any>({
    default: null,
    type: Function
  });
  showMaxLength = prop<boolean>({
    default: false,
    type: Boolean
  });
  hint = prop<string>({
    default: "",
    type: String
  });
  debounce = prop<number>({
    default: 0,
    type: Number
  });
  disableSpace = prop<boolean>({
    default: false,
    type: Boolean
  });
  uppercase = prop<boolean>({
    default: false,
    type: Boolean
  });
  bold = prop<boolean>({
    default: false,
    type: Boolean
  });
  submit = prop<any>({
    default: null,
    type: Function
  });
  sizeInput = prop<string>({
    default: "14px",
    type: String
  });
  labelStacked = prop<string>({
    default: "",
    type: String
  });
  errorIcon = prop<boolean>({
    default: true,
    type: Boolean
  });
  textAreaRows = prop<string>({
    default: "5",
    type: String
  });
  maxValueNumber = prop<number>({
    default: 0,
    type: Number
  });
  isMandatory = prop<boolean>({
    default: false,
    type: Boolean
  });
  disableBackground = prop<boolean>({
    default: false,
    type: Boolean
  });
  isModelUpdated = prop<boolean>({
    default: true,
    type: Boolean
  });
  isFirstInput = prop<boolean>({
    default: false,
    type: Boolean
  });
  resizableTextarea = prop<boolean>({
    default: false,
    type: Boolean
  });
  preventEnterTextArea = prop<boolean>({
    default: false,
    type: Boolean
  });
  isCapitalize = prop<boolean>({
    default: false,
    type: Boolean
  });
  isErrorIconInput = prop<boolean>({
    default: true,
    type: Boolean
  });
  isErrorIconCaption = prop<boolean>({
    default: true,
    type: Boolean
  });
  inputChecked = prop<boolean>({
    default: false,
    type: Boolean
  });
  customClassSuffix = prop<string>({
    default: "",
    type: String
  });
  sizeProportional = prop<string>({
    default: "py-2.5",
    type: String
  });
  isError = prop<boolean>({
    default: false,
    type: Boolean
  });
  flag = prop<string>({
    default: "",
    type: String
  });
  showViewDisabledInput = prop<boolean>({
    default: false,
    type: Boolean
  })
}

@Options({
  inheritAttrs: false,
  emits: [
    "update:modelValue",
    "update:isError",
    "change",
    "enter",
    "blur",
    "focus",
    "onClickSuffix",
    "onClickViewDisabledInput"
  ],
  watch: {
    modelValue() {
      this.$emit("update:isError", !this.modelValue || !!this.errorMessage);
      if (this.maxLength && this.modelValue?.length > Number(this.maxLength)) {
        this.$emit(
          "update:modelValue",
          this.modelValue.slice(0, Number(this.maxLength))
        );
      }
    }
  }
})
export default class LpInput extends Vue.with(Props) {
  mounted() {
    if (this.isFirstInput) {
      const pseudoInput: any = this.$refs.pseudoInput;
      pseudoInput?.focus();
    }
  }

  updateValue(e: any) {
    if (this.type === "number") {
      e.target.value = numberOnly(e.target.value);
    }
    if (this.format) {
      e.target.value = this.format(e.target.value);
    }
    this.$emit("change", e.target.value);
    if (this.isModelUpdated) {
      this.$emit("update:modelValue", e.target.value);
    }
  }

  get maxValueNumberMessage() {
    if (this.format) {
      return this.format(parseFloat(`${this.maxValueNumber}`));
    } else return this.maxValueNumber;
  }

  get errorMaxValueNumber() {
    return (
      this.modelValue &&
      this.maxValueNumber &&
      !isNaN(commaDecimalToDecimal(this.modelValue.replace(/\./g, ""))) &&
      commaDecimalToDecimal(this.modelValue.replace(/\./g, "")) >
        this.maxValueNumber
    );
  }
  isFocused = false;
  onFocus(e: any) {
    this.isFocused = true;
    this.$emit("focus", e);
  }

  get errorMandatory() {
    return this.isFocused && this.isMandatory && !this.modelValue;
  }

  // method to refocus input element
  focus() {
    const inputForm: any = this.$refs.inputForm;
    inputForm?.focus();
  }

  blur() {
    const inputForm: any = this.$refs.inputForm;
    inputForm?.blur();
  }

  onEnterTextArea(event: any) {
    if (this.preventEnterTextArea) {
      event.preventDefault();
    }
  }

  get errorMinLength() {
    return (this.modelValue &&
      this.modelValue.length < Number(this.minLength)) ||
      (this.type === "number" && +this.modelValue < +this.min)
      ? `Min. ${this.minLength || this.min}`
      : "";
  }
  get errorMessage() {
    const errorMax =
      this.errorMaxValueNumber ||
      (this.type === "number" && +this.modelValue > +this.max)
        ? `Maks. ${this.maxValueNumberMessage || this.max}`
        : "";
    return this.errorCaption || this.errorMinLength || errorMax;
  }

  onClickInput() {
    this.$emit("onClickViewDisabledInput");
  }
}
